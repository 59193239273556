<template>
  <div class="layout">
    <Sider
      :style="{position: 'fixed', height: '100vh', left: 0, overflow: 'auto', background: '#fff'}"
    >
      <Menu
        ref="side_menu"
        accordion
        theme="light"
        style="width: 100%"
        :active-name="activeIndex"
        :open-names="openNames"
      >
        <div style="width:100%;margin:0px auto;height:64px;line-height:120px">
          <img class="logo" src="../assets/logo.jpg" style="width:40%" />
        </div>
        <div
          v-for="(item, key) in siderMenu"
          :key="key"
          v-if="item.checked"
          style="maigin-top:2px;"
        >
          <!-- 没有子菜单 -->
          <MenuItem
            v-if="!item.children.length>0"
            :to="{ name: item.index }"
            class="align-justify"
            :name="item.index"
          >
            <Icon :type="item.iconType" />
            {{ item.title }}
          </MenuItem>
          <!-- 有子菜单 -->
          <Submenu v-else class="align-justify" :name="item.index">
            <template slot="title">
              <Icon :type="item.iconType" />
              {{ item.title }}
            </template>
            <!-- 子菜单 -->
            <MenuItem
              v-for="(citem, ckey) in item.children"
              :key="ckey"
              :to="{ name: citem.index }"
              :name="citem.index"
              v-if="citem.checked"
            >{{ citem.title }}</MenuItem>
          </Submenu>
        </div>
      </Menu>
    </Sider>
    <Layout :style="{marginLeft: '200px'}">
      <Header
        style="position: fixed;width: 100%;z-index:999;text-align:left;display:flex;background: #fff"
      >
        <Breadcrumb style="margin-left:20px">
          <BreadcrumbItem
            v-for="(item, key) in this.$store.state.breadcrumb.bItems"
            :key="key"
          >{{ item }}</BreadcrumbItem>
        </Breadcrumb>
        <div style="position: fixed;right: 0px;">
          <span style="color:#5b6270;padding: 0 15px;">欢迎使用，{{adminName}}</span>
          <span style="color:#5b6270">
            <Button type="text" ghost @click="logout">
              <Icon type="md-log-in" />
            </Button>
          </span>
          <span style="color:#5b6270">
            <Button type="text" ghost @click="updatePwd">
              <Icon type="md-lock" />
            </Button>
          </span>
        </div>
      </Header>
      <Content :style="{padding: '0px 12px 12px',margin:'70px 0 0 0', background: '#fff'}">
        <Card>
          <div>
            <router-view />
          </div>
        </Card>
        <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>正在加载...</div>
        </Spin>
      </Content>
    </Layout>
  </div>
</template>
<script>
import cookie from "js-cookie";
import SideMenu from "ivew-side-menu-search-chg";
export default {
  name: "layout",
  components: {
    SideMenu,
  },
  data() {
    return {
      spinShow: true,
      pwd: "",
      adminName: "",
      mainHeight: (window.innerHeight - 129) / 16 + "rem",
      mainboxHeight: (window.innerHeight - 177) / 16 + "rem",
      siderMenu: [],
      activeIndex: "Home",
      openNames: ["Home", "Welcome"],
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        (this.mainHeight = (window.innerHeight - 129) / 16 + "rem"),
          (this.mainboxHeight = (window.innerHeight - 177) / 16 + "rem");
      })();
    };
    this.adminName = localStorage.getItem("adminName");
    this.spinShow = true;
    // this.$Spin.show({
    //   render: (h) => {
    //     return h("div", [
    //       h("Icon", {
    //         class: "demo-spin-icon-load",
    //         props: {
    //           type: "ios-loading",
    //           size: 18,
    //         },
    //       }),
    //       h("div", "加载中···"),
    //     ]);
    //   },
    // });
    this.$get("Role/GetMenuByRole", {
      roleId: localStorage.getItem("roleId"),
      requestName: "getRole",
    }).then((res) => {
      this.siderMenu = res.data.responseList;
      // this.$Spin.hide();
      this.$nextTick(() => {
        this.$refs.side_menu.updateOpened();
        this.$refs.side_menu.updateActiveName();
      });
      this.$get("Role/GetDataRole", {
        roleId: localStorage.getItem("roleId"),
      }).then((dataRole) => {
        localStorage.setItem(
          dataRole.data.responseList[0].dataKey,
          dataRole.data.responseList[0].selectRegion
        );
        localStorage.setItem(
          dataRole.data.responseList[1].dataKey,
          dataRole.data.responseList[1].selectRegion
        );
        this.spinShow = false;
      });
    });
  },
  updated() {
    this.activeIndex = this.$route.name;
  },
  watch: {
    $route() {
      let bItems = this.$route.meta.bItems;
      this.$store.dispatch("changeBitems", bItems);
    },
  },

  methods: {
    updatePwd() {
      this.$Modal.warning({
        title: "修改密码",
        render: (h) => {
          return h("Input", {
            props: {
              value: this.pwd,
              autofocus: true,
              size: "large",
              placeholder: "请输入您的新密码",
              type: "password",
              maxlength: "16",
              "show-word-limit": true,
            },
            on: {
              input: (val) => {
                this.pwd = val;
              },
            },
          });
        },
        onOk: () => {
          var pattern = new RegExp("^[a-zA-Z0-9_]{4,11}$");
          console.log(pattern.test(this.pwd));
          if (!pattern.test(this.pwd)) {
            this.$Message.error("密码格式错误,只允许输入英文或数字,且为6-16位");
            return;
          }
          let adminid = localStorage.getItem("adminId");
          this.$post("Admin/ChangePwd", {
            Pwd: this.pwd,
            AdminId: adminid,
          }).then((res) => {
            if (res.data.code) {
              this.$Message.success("修改密码成功,请重新登录");
              setTimeout(() => {
                cookie.remove("token");
                this.$router.push({
                  path: "/login",
                });
              }, 1500);
            }
          });
        },
      });
    },
    logout() {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确认退出?</p>",
        onOk: () => {
          cookie.remove("token");
          this.$router.push({
            path: "/login",
          });
        },
        onCancel: () => {
          this.$Message.info("取消");
        },
      });
    },
    destroyed() {
      window.onresize = null;
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.layout {
  // border: 1px solid #d7dde4;
  background: #f5f7f9;
  // position: relative;
  // border-radius: 4px;
  // overflow: hidden;
}
.ivu-layout-sider-trigger {
  background: #fff;
}
.ivu-layout-header {
  background: #fff !important;
  padding: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.layout-nav {
  margin: 0 auto;
  margin-right: 200px;
  float: right;
}
.ivu-breadcrumb {
  color: #5b6270;
}
.ivu-breadcrumb > span:last-child {
  color: #5b6270;
}

.ivu-menu-horizontal {
  height: 64px;
  line-height: 64px;
}
.ivu-menu-horizontal .ivu-menu-item,
.ivu-menu-horizontal .ivu-menu-submenu {
  position: fixed;
  right: 10px;
}
.ivu-btn-ghost {
  color: #5b6270;
}
.ivu-menu-vertical.ivu-menu-light:after {
  height: 100vh;
}
// 加载
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
